import React from "react";
import PropTypes from "prop-types";

import BreadcrumbList from "components/search/Breadcrumbs/BreadcrumbList";
import BreadcrumbSchema from "components/search/Breadcrumbs/BreadcrumbSchema";
import { Button } from "components/core/Button";
import { Chevron } from "components/core/Icons";
import navigate from "utils/navigate";

import "./Breadcrumbs.scss";

const Breadcrumbs = ({ cityUrl, stateUrl, location, facilityUrl, allowTruncation, facilityName }) => {
	const { state, fullState, preferredPlaceName, streetAddress1 } = location;

	const breadcrumbs = [
		{
			label: "Home",
			href: "/",
			segmentLabel: "/"
		}
	];

	if (stateUrl) {
		breadcrumbs.push({
			label: fullState,
			name: state,
			href: stateUrl,
			segmentLabel: stateUrl,
			segmentProperties: { breadcrumb: fullState }
		});
	}

	if (cityUrl) {
		breadcrumbs.push({
			label: preferredPlaceName,
			name: preferredPlaceName,
			href: cityUrl,
			segmentLabel: cityUrl,
			segmentProperties: {
				breadcrumb: `${preferredPlaceName} Storage`
			}
		});
	}

	if (facilityUrl) {
		breadcrumbs.push({
			label: facilityName,
			name: facilityName,
			href: facilityUrl,
			segmentLabel: facilityUrl,
			segmentProperties: { breadcrumb: streetAddress1 }
		});
	}

	const previousBreadcrumb = breadcrumbs[breadcrumbs.length-2] || breadcrumbs[0];

	const handleBackButtonClick = (previousBreadcrumbHref) => {
		navigate(previousBreadcrumbHref);
	};

	return (
		<div className="breadcrumbs">
			<div>
				<Button
					theme="icon"
					onClick={() => handleBackButtonClick(previousBreadcrumb.href)}
					className="back-button"
					title={`Navigate to ${
						previousBreadcrumb.label
					}`}
				>
					<Chevron
						fill="blue"
						rotate="left"
					/>
				</Button>
				<BreadcrumbList breadcrumbs={breadcrumbs} allowTruncation={allowTruncation}/>
			</div>
			<BreadcrumbSchema breadcrumbs={breadcrumbs} />
		</div>
	);
};

Breadcrumbs.defaultProps = {
	cityUrl: "",
	location: {},
	stateUrl: ""
};

Breadcrumbs.propTypes = {
	cityUrl: PropTypes.string,
	location: PropTypes.object,
	stateUrl: PropTypes.string,
	facilityUrl: PropTypes.string,
	allowTruncation: PropTypes.bool,
	facilityName: PropTypes.string
};

export default Breadcrumbs;
