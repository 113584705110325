import React from "react";
import PropTypes from "prop-types";
import { Schema } from "@sparefoot/react-commons";

export const createBreadcrumbSchema = (breadcrumbs) => ({
	"@context": "http://schema.org",
	"@type": "BreadcrumbList",
	itemListElement: breadcrumbs
		.filter((crumb) => crumb.label)
		.map((crumb, index) => ({
			"@type": "ListItem",
			position: index + 1,
			item: {
				"@type": "Website",
				"@id": `storage.com${crumb.href}`,
				name: crumb.name || crumb.label
			}
		}))
});

const BreadcrumbSchema = ({ breadcrumbs }) => {
	const breadcrumbJSON = createBreadcrumbSchema(breadcrumbs);
	return <Schema schema={breadcrumbJSON} />;
};

BreadcrumbSchema.propTypes = {
	breadcrumbs: PropTypes.instanceOf(Array)
};

export default BreadcrumbSchema;
